import React, { useState, useEffect } from "react";
// useState�������������״̬����useEffect�������������/״̬�仯ʱִ��
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import pdf from "../../Assets/Xingci_Resume.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Resume() {
    // ��useState����width״̬����ʼֵΪ��ǰ���ڿ��ȣ�������scale״̬����ʼֵΪ1.5
    const [width, setWidth] = useState(window.innerWidth);
    const [scale, setScale] = useState(1.5);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            // ���ݴ��ڿ��ȶ�̬���� scale
            if (window.innerWidth > 1200) {
                setScale(1.8);
            } else if (window.innerWidth > 786) {
                setScale(1.4);
            } else {
                setScale(0.7);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div style={{ overflowX: "hidden" }}>
            <Container fluid className="resume-section">
                <Row className="resume" style={{ marginTop: "-80px", paddingLeft: `${(width - scale * 595.28) / 2}px`, paddingRight: `${(width - scale * 595.28) / 2}px` }}>
                    <Document file={pdf} className="d-flex justify-content-center">
                        <Page pageNumber={1} scale={scale} />
                    </Document>
                </Row>
                <Row style={{ justifyContent: "center", marginTop: "-30px" }}>
                    <Button
                        variant="primary"
                        href={pdf}
                        target="_blank"
                        style={{ maxWidth: "180px" }}
                    >
                        <div style={{ color: "#ffffff" }}>
                            <AiOutlineDownload />
                            &nbsp;Download CV
                        </div>
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

export default Resume;
