import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { SiBilibili } from "react-icons/si";
/* 这里function定义了一个名为Footer的函数组件 
   而return代表指定该组件渲染的内容
   在最后export default Footer意味着将Footer导出为默认模块，用于复用目的
   在其他文件中复用组件即只需要import Footer from './Footer' */
function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="3" className="footer-copywright">
          <h3>
            <a href="mailto:xingcig@andrew.cmu.edu" style={{ color: "white", textDecoration: "none" }}>
              xingcig@andrew.cmu.edu
             </a>
           </h3>
        </Col>
        <Col md="6" className="footer-copywright">
          <h3>Copyright © 2024 &nbsp;X.&nbsp;Gu.&nbsp;&nbsp;All rights reserved</h3>
        </Col>
        <Col md="3" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/0FFMIND"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://space.bilibili.com/39429643"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
               >
               <SiBilibili />
              </a>
             </li>
             {/* li 创建列表元素，并添加了social-icons的css样式 
                 而a标签用来表示超链接，href指定链接时跳转目标
                 style表示显示时颜色为白色
                 target = "_blank"表示点击链接时新建浏览器打开界面
              */}
             <li className="social-icons">
               <a
                 href="https://www.linkedin.com/in/xingcig/"
                 style={{ color: "white" }}
                 target="_blank"
                 rel="noopener noreferrer"
                >
               <AiFillLinkedin />
               </a>
             </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
